import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import { Breadcrumb } from 'antd';

export default function JobPost({ data }) {
    const post = data.markdownRemark

    let applyEmailMessage = "mailto:hello@rummo.to?subject=Career opportunity at Rummo&body=Hi. I would like to apply for the " + post.frontmatter.title + " position and to know more about Rummo's career opportunities. My details: - Name..., - Phone..., - LinkedIn..., - C.V. (attached). Thank you."

    return (
        <Layout>
            <div>

                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/careers">Careers</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {post.frontmatter.title}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <br />
                <h3>{post.frontmatter.area} - {post.frontmatter.office}</h3>
                <h2>{post.frontmatter.title}</h2>
                <br />
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
                <br />
                <div className='button' style={{ width: '300px', textAlign: 'center' }} onClick={() => window.open(applyEmailMessage)}>Apply Now</div>
            </div>
        </Layout>
    )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        area
        office
      }
    }
  }
`